/* eslint-disable @typescript-eslint/indent */
import { IDropdownData } from '@pwskills/rachnaui/components/Navbar/Navbar.types';
import { INavigation } from '@/interfaces/homePage';
import { Environment } from '@/GlobalEnums/enums';
import { environment } from '../common/env.constants';
import { DOMAIN } from './env.constants.utils';

let env = '';

if (environment !== Environment.PRODUCTION) {
  env = `-${environment}`;
}
const statistics = [
  {
    id: 1,
    imgLink: '/images/homePage/statistics1.png',
    subTitle: '55%',
    title: 'Average Salary Hike',
  },
  {
    id: 2,
    imgLink: '/images/homePage/statistics2.png',
    subTitle: '600+',
    title: 'Different Courses',
  },
  {
    id: 3,
    imgLink: '/images/homePage/statistics3.png',
    subTitle: '12000+',
    title: 'Career Transitions',
  },
  {
    id: 4,
    imgLink: '/images/homePage/statistics4.png',
    subTitle: '400+',
    title: 'Hiring Partners',
  },
];

const ourProducts = [
  // {
  //   id: 1,
  //   imgLink: '/images/homePage/products-icon1.png',
  //   title: 'PW Skills Lab',
  //   description: 'Buying a new PC is no longer required, Access unlimited computing power!',
  //   link: `https://lab${env}.pwskills.com`,
  //   hoverBg: 'hover:bg-[#F0F8FB]',
  //   bg: 'bg-[#F0F8FB]',
  // },
  {
    id: 2,
    imgLink: '/images/homePage/products-icon2.png',
    title: 'Experience Portal',
    description: 'Self-paced portal prioritizes hands-on training with 570+ internship projects.',
    link: `https://experience${env}.pwskills.com`,
    hoverBg: 'hover:bg-[#FFF2F8]',
    bg: 'bg-[#FFF2F8]',
  },
  {
    id: 3,
    imgLink: '/images/homePage/products-icon3.png',
    title: 'Hall Of Fame',
    description: 'Our student placements and 100K+ career transitions speak volumes.',
    link: `https://halloffame${env}.pwskills.com`,
    hoverBg: 'hover:bg-[#FDF5FF]',
    bg: 'bg-[#FDF5FF]',
  },
  {
    id: 4,
    imgLink: '/images/homePage/products-icon4.png',
    title: 'Job Portal',
    description: 'Use exceptional templates for a stand-out resume minus the sign up process.',
    link: `https://jobs${env}.pwskills.com`,
    hoverBg: 'hover:bg-[#FDFBF0]',
    bg: 'bg-[#FDFBF0]',
  },
  {
    id: 5,
    imgLink: '/images/homePage/products-icon5.png',
    title: 'Become an affiliate',
    description: 'Explore affiliate marketing opportunities and attain financial freedom.',
    link: `https://affiliate${env}.pwskills.com`,
    hoverBg: 'hover:bg-[#F6FAFF]',
    bg: 'bg-[#F6FAFF]',
  },
];

const coursesList = [
  {
    id: '1',
    language: 'Hindi',
    thumbnail: '/public/images/homePage/dummy/thumb.jpeg',
    title: 'Java & DSA',
    mentorName: ['Naveen reddy'],
    startsDate: '20th jan',
    courseKeys: ['Doubt class', 'Liveclass', 'Notes'],
    Discountedprice: '3000',
    courseFor: 'student',
    TotalPrice: '₹4500',
    DiscountPercentage: '10%',
    videoLink: 'https://www.youtube.com/embed/L5-8E3q72PY',
    courseType: 'Live',
  },
  {
    id: '2',
    language: 'English',
    thumbnail: '/public/images/homePage/dummy/thumb2.jpeg',
    title: 'Full stack Data Science with Javascript',
    mentorName: ['Hitesh Choudary', 'Anuragh'],
    startsDate: '20th jan 2022',
    courseKeys: ['Doubt class', 'Liveclass', 'Notes'],
    Discountedprice: '30000',
    courseFor: 'college',
    TotalPrice: '₹45000',
    DiscountPercentage: '10%',
    courseType: 'Affordable',
  },
  {
    id: '3',
    language: 'Hinglish',
    thumbnail: '/public/images/homePage/dummy/thumb.jpeg',
    title: 'Cracking the Coding Interview in Java - Foundation',
    mentorName: ['Vishwa Mohan', 'Manvi Tyagi', 'Anjali Sheel'],
    startsDate: '20th jan 2022',
    courseKeys: ['Doubt class', 'Liveclass', 'Notes'],
    Discountedprice: '30000',
    courseFor: 'student',
    TotalPrice: '45000',
    DiscountPercentage: '10%',
    courseType: 'Live',
  },
  {
    id: '5',
    language: 'Hinglish',
    thumbnail: '/public/images/homePage/dummy/thumb.jpeg',
    title: 'Cracking the Coding Interview in Java - Foundation',
    mentorName: ['Vishwa Mohan', 'Manvi Tyagi', 'Anjali Sheel'],
    startsDate: '20th jan 2022',
    courseKeys: ['Doubt class', 'Liveclass', 'Notes'],
    Discountedprice: '30000',
    courseFor: 'student',
    TotalPrice: '45000',
    DiscountPercentage: '10%',
    courseType: 'Popular',
  },
  {
    id: '5',
    language: 'Hinglish',
    thumbnail: '/public/images/homePage/dummy/thumb.jpeg',
    title: 'Cracking the Coding Interview in Java - Foundation',
    mentorName: ['Vishwa Mohan', 'Manvi Tyagi', 'Anjali Sheel'],
    startsDate: '20th jan 2022',
    courseKeys: ['Doubt class', 'Liveclass', 'Notes'],
    Discountedprice: '30000',
    courseFor: 'student',
    TotalPrice: '45000',
    DiscountPercentage: '10%',
    courseType: 'Popular',
  },
  {
    id: '5',
    language: 'Hinglish',
    thumbnail: '/public/images/homePage/dummy/thumb.jpeg',
    title: 'Cracking the Coding Interview in Java - Foundation',
    mentorName: ['Vishwa Mohan', 'Manvi Tyagi', 'Anjali Sheel'],
    startsDate: '20th jan 2022',
    courseKeys: ['Doubt class', 'Liveclass', 'Notes'],
    Discountedprice: '30000',
    courseFor: 'student',
    TotalPrice: '45000',
    DiscountPercentage: '10%',
    courseType: 'Popular',
  },
  {
    id: '5',
    language: 'Hinglish',
    thumbnail: '/public/images/homePage/dummy/thumb.jpeg',
    title: 'Cracking the Coding Interview in Java - Foundation',
    mentorName: ['Vishwa Mohan', 'Manvi Tyagi', 'Anjali Sheel'],
    startsDate: '20th jan 2022',
    courseKeys: ['Doubt class', 'Liveclass', 'Notes'],
    Discountedprice: '30000',
    courseFor: 'student',
    TotalPrice: '45000',
    DiscountPercentage: '10%',
    courseType: 'Popular',
  },
  {
    id: '6',
    language: 'Hinglish',
    thumbnail: '/public/images/homePage/dummy/thumb.jpeg',
    title: 'Cracking the Coding Interview in Java - Foundation',
    mentorName: ['Vishwa Mohan', 'Manvi Tyagi', 'Anjali Sheel'],
    startsDate: '20th jan 2022',
    courseKeys: ['Doubt class', 'Liveclass', 'Notes'],
    Discountedprice: '30000',
    courseFor: 'student',
    TotalPrice: '45000',
    DiscountPercentage: '10%',
    courseType: 'Popular',
  },
  {
    id: '7',
    language: 'Hinglish',
    thumbnail: '/public/images/homePage/dummy/thumb.jpeg',
    title: 'Cracking the Coding Interview in Java - Foundation',
    mentorName: ['Vishwa Mohan', 'Manvi Tyagi', 'Anjali Sheel'],
    startsDate: '20th jan 2022',
    courseKeys: ['Doubt class', 'Liveclass', 'Notes'],
    Discountedprice: '30000',
    courseFor: 'student',
    TotalPrice: '45000',
    DiscountPercentage: '10%',
    courseType: 'Popular',
  },
];

const ProductionProductNavMenu: IDropdownData[] = [
  {
    title: 'Online Degree',
    autoLogin: false,
    href: `${DOMAIN}/online-degree?source=pwskills.com&position=nav_bar&buttonText=online_degree`,
    subMenu: [],
    callbackUrl: '/',
    _id: 'online_degree',
    tokenGenerateLink: ``,
  },
  {
    title: 'Masterclass',
    autoLogin: false,
    href: `${DOMAIN}/masterclass/?source=pwskills.com&position=nav_bar&buttonText=masterclass`,
    subMenu: [],
    _id: 'master_classes',
    icon: '/images/navbar/MasterClassNavbarGif.gif',
    tokenGenerateLink: ``,
  },
];

// Footer Product Link
const ProductionProductFooterMenu: INavigation[] = [
  // {
  //   name: 'Campus Edge',
  //   href: '/campus-edge',
  // },
  // {
  //   name: 'PW Skills Lab',
  //   href: 'https://lab.pwskills.com/?source=pwskills.com&position=footer',
  // },
  {
    name: 'Job Portal',
    href: 'https://jobs.pwskills.com/?source=pwskills.com&position=footer',
  },
  {
    name: 'Experience Portal',
    href: 'https://experience.pwskills.com/?source=pwskills.com&position=footer',
  },
  {
    name: 'Become an affiliate',
    href: 'https://affiliate.pwskills.com/?source=pwskills.com&position=footer',
  },
  {
    name: 'Hall Of fame',
    href: 'https://halloffame.pwskills.com/?source=pwskills.com&position=footer',
  },
  {
    name: 'Blog',
    href: 'https://pwskills.com/blog',
  },
];

const devProductFooterMenu: INavigation[] = [
  // {
  //   name: 'Campus Edge',
  //   href: '/campus-edge',
  // },
  // {
  //   name: 'PW Skills Lab',
  //   href: 'https://lab-dev.pwskills.com/',
  // },
  {
    name: 'Job Portal',
    href: 'https://jobs-dev.pwskills.com/',
  },
  {
    name: 'Experience Portal',
    href: 'https://experience-dev.pwskills.com/',
  },
  {
    name: 'Become an affiliate',
    href: 'https://affiliate-dev.pwskills.com/',
  },
  {
    name: 'Hall Of fame',
    href: 'https://halloffame-dev.pwskills.com/',
  },
  {
    name: 'Blog',
    href: 'https://pwskills.com/blog',
  },
];

const StagingProductFooterMenu: INavigation[] = [
  // {
  //   name: 'Campus Edge',
  //   href: '/campus-edge',
  // },
  // {
  //   name: 'PW Skills Lab',
  //   href: 'https://lab-staging.pwskills.com/',
  // },
  {
    name: 'Job Portal',
    href: 'https://jobs-staging.pwskills.com/',
  },
  {
    name: 'Experience Portal',
    href: 'https://experience-staging.pwskills.com/',
  },
  {
    name: 'Become an affiliate',
    href: 'https://affiliate-staging.pwskills.com/',
  },
  {
    name: 'Hall Of fame',
    href: 'https://halloffame-staging.pwskills.com/',
  },
  {
    name: 'Blog',
    href: 'https://pwskills.com/blog',
  },
];

const CompanyFooterLink = [
  {
    name: 'About us',
    href: '/about-us',
  },
  {
    name: 'Contact us',
    href: '/contact-us',
  },
  {
    name: 'FAQ',
    href: '/faqs',
  },
  {
    name: 'Job assistance',
    href: '/job-assistance',
  },
  {
    name: 'Privacy policy',
    href: '/privacy-policy',
  },
  {
    name: 'Terms and condition',
    href: '/terms-and-conditions',
  },
  {
    name: 'PW IOI',
    href: 'https://pwioi.com',
  },
];

const ProductListNavLink = ProductionProductNavMenu;

const ProductListFooterLink =
  environment === 'dev'
    ? devProductFooterMenu
    : environment === 'staging'
    ? StagingProductFooterMenu
    : ProductionProductFooterMenu;

const renderPwSkillsUrl = DOMAIN as string;

export {
  statistics,
  ourProducts,
  coursesList,
  ProductListNavLink,
  ProductListFooterLink,
  CompanyFooterLink,
  renderPwSkillsUrl,
};
